/* eslint-disable no-undef */
import { Controller } from "@hotwired/stimulus"
import { fetchUrl } from "../../utils/fetch"

class Clipboard extends Controller {
  static targets = ["source"]

  connect() {
    this.errorMessage = gettext("Impossible de copier le texte dans le presse-papier.")
  }

  copyFromUrlContent(e) {
    // https://wolfgangrittner.dev/how-to-use-clipboard-api-in-safari/
    // https://wolfgangrittner.dev/how-to-use-clipboard-api-in-firefox/
    e.preventDefault()
    const target = e.currentTarget
    const {
      sourceMethod,
      message,
      url,
    } = { ...e.params }

    try {
      if (typeof ClipboardItem && navigator.clipboard.write) {
        const item = new ClipboardItem({
          "text/plain": this._fetchTextToCopy(e, url)
            .then(() => this._getText(sourceMethod, target))
            .then(text => new Blob([text], { type: "text/plain" })),
          "text/html": this._fetchTextToCopy(e, url)
            .then(() => this._getText(sourceMethod, target))
            .then(text => new Blob([text], { type: "text/html" })),
        })

        navigator.clipboard.write([item])
          .then(() => this._dispatch(message, "success"))
          .catch(err => {
            this._dispatch(this.errorMessage, "error")
            this._handleError(err, this._getText(sourceMethod, target))
          })
      } else {
      // Fallback for browsers that don't support ClipboardItem
        const text = this._getText(sourceMethod, target)
        this._fetchTextToCopy(e, url)
          .then(() => navigator.clipboard.writeText(text))
          .then(() => this._dispatch(message, "success"))
          .catch(error => {
            this._dispatch(this.errorMessage, "error")
            this._handleError(error, text)
          })
      }
    } catch (error) {
      this._dispatch(this.errorMessage, "error")
    }
  }

  async copy(event) {
    const { sourceMethod } = { ...event.params }
    const text = this._getText(sourceMethod, event.currentTarget)

    try {
      await navigator.clipboard.write([
        new ClipboardItem({
          "text/plain": new Blob([text], { type: "text/plain" }),
          "text/html": new Blob([text], { type: "text/html" }),
        }),
      ])
      alert(gettext("Le contenu a bien été copié dans le presse-papier."))
    } catch (error) {
      this._handleError(error, text)
    }
  }

  async _fetchTextToCopy(e, url) {
    const target = e.currentTarget
    const feature = e.params.feature
    try {
      const data = await fetchUrl(url)
      const text = feature === "copy" ? data.copy : data.link
      target.dataset.clipboardDataText = text
    } catch (error) {
      console.error("Could not retrieve text to copy.", error)
    }
  }

  _getText(sourceMethod, target) {
    if (sourceMethod === "value") {
      return this.sourceTarget.value
    } else if (sourceMethod === "dataset") {
      return target.dataset.clipboardDataText
    } else {
      return alert(this.errorMessage)
    }
  }

  _handleError(error, text) {
    console.error("Failed to copy text to clipboard: ", error)
    window.prompt(gettext("Copier dans le presse-papier : Cmd + C, Entrée"), text)
  }

  _dispatch(message, bannerType) {
    this.dispatch("toast", {
      detail: {
        message,
        bannerType,
      },
    })
  }
}

export { Clipboard }
