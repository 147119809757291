export async function fetchUrl(url) {
  const response = await fetch(url,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  )

  if (!response.ok) {
    throw new Error(`Could not fetch ${url}: status ${response.status}`)
  }

  return response.json()
}
