import { Controller } from "@hotwired/stimulus"

const SETTINGS_BANNER_CLOSED = "co_banner_closed"

class Banner extends Controller {
  static classHidden = "-hidden"
  static values = {
    bannerId: Number,
  }

  show() {
    this.element.classList.remove(Banner.classHidden)
  }

  hide() {
    this.element.classList.add(Banner.classHidden)
  }

  handleClickClose = (ev) => {
    localStorage.setItem(this.getStorageKey(), true)

    this.hide()
  }

  getStorageKey() {
    if (this.bannerIdValue) {
      return SETTINGS_BANNER_CLOSED + "_" + this.bannerIdValue
    }
    return SETTINGS_BANNER_CLOSED
  }

  connect() {
    if (!this.hasBannerIdValue) return
    const isBannerClosed = localStorage.getItem(this.getStorageKey())
    if (isBannerClosed) {
      this.hide()
    } else {
      this.show()
    }
  }
}

class Toaster extends Banner {
  static classToaster = ".toaster"
  static classBanner = ".banner"
  static classVisible = "-visible"
  static classSuccess = "-success"
  static classError = "-error"

  toast({ detail: { message, bannerType } }) {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }

    const toaster = document.querySelector(Toaster.classToaster)
    if (!toaster) return

    const text = toaster.querySelector("p")
    text.innerText = message
    const banner = toaster.querySelector(Toaster.classBanner)
    banner.classList.remove(Toaster.classSuccess, Toaster.classError)
    banner.classList.add(`-${bannerType}`)
    const icon = toaster.querySelector("svg use")
    icon.setAttribute("href", `/static/ui/base/images/icons/${bannerType}.svg#root`)

    if (!toaster.classList.contains(Toaster.classVisible)) {
      toaster.classList.add(Toaster.classVisible)
    }

    this.timeoutId = setTimeout(() => {
      toaster.classList.remove(Toaster.classVisible)
      this.timeoutId = null
    }, 3000)
  }

  connect() {
    this.timeoutId = null
  }
}

export { Banner, Toaster }
